<template>
    <pharmaceutics-module-component></pharmaceutics-module-component>
</template>

<script>
    import PharmaceuticsModuleComponent from "@/components/admin/modules/PharmaceuticsModuleComponent";
    export default {
        name: "PharmaceuticsModule",
        title: "Gestión de Farmacias | Turismo BC",
        components: {PharmaceuticsModuleComponent}
    }
</script>

<style scoped>

</style>